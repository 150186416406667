import 'primeicons/primeicons.css';
import 'primereact/resources/themes/mdc-light-deeppurple/theme.css'
import 'primereact/resources/primereact.css';
import './styles.css';
import { MdOutlineRefresh } from "react-icons/md";
import React, { useState, useMemo } from 'react';
import { DataTable as DataTablePrimeReact, DataTableProps } from 'primereact/datatable';
import { Input } from '../Input';
import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { Tooltip } from '@mui/material';
import { ButtonFooter } from '../ExButtonDataTable';

const MAX_ROWS = 50;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
`

interface ExDataTableProps extends DataTableProps {
    refresh: any;
}

export const DataTable: React.FC<ExDataTableProps> = ({ refresh, ...props }) => {
    const [globalFilter, setGlobalFilter] = useState<any>('');

    const filteredData = useMemo(() => {
        if (!props.value) {
            return [];
        }

        return props.value.filter((item) =>
            Object.values(item).some((field) =>
                String(field).toLowerCase().includes(globalFilter.toLowerCase())
            )
        );
    }, [props.value, globalFilter]);

    return (
        <DataTablePrimeReact
            {...props}
            header={
                <Header>
                    <Col>
                        <Input
                            name="search"
                            id="search"
                            type="text"
                            placeholder="Pesquisar"
                            size="md"
                            search
                            onAfterChange={(value: any) => setGlobalFilter(value)}
                        />
                    </Col>

                </Header>
            }
            paginatorRight={
                <Tooltip title="Recarregar">
                    <ButtonFooter onClick={() => refresh()}>
                        <MdOutlineRefresh />
                    </ButtonFooter>
                </Tooltip>
            }
            paginatorLeft={<></>}
            rowHover
            size="small"
            responsiveLayout="stack"
            breakpoint="960px"
            resizableColumns
            emptyMessage="Nenhum registro encontrado."
            rows={MAX_ROWS}
            paginatorTemplate={
                Number(props.value?.length) > MAX_ROWS
                    ? "RowsPerPageDropdown PrevPageLink CurrentPageReport NextPageLink"
                    : "CurrentPageReport"
            }
            currentPageReportTemplate={
                Number(props.value?.length) > MAX_ROWS
                    ? "{first} à {last} de {totalRecords}"
                    : "{totalRecords} Registros"
            }
            expandedRowIcon="pi pi-angle-down"
            collapsedRowIcon="pi pi-angle-right"
            paginator={true}
            scrollHeight={'100%'}
            sortField={props.sortField}
            sortOrder={1}
            value={filteredData}
        />
    );
};
