import Container from 'react-bootstrap/Container';
import { Navbar, Nav, Dropdown, Row } from 'react-bootstrap';
import Logo from '../../images/logoPequena.png'
import { useAuth } from '../../contexts/AuthProvider/useAuth';
import { AppRoutes } from '../../routes';
import { DropdownItem, DropdownToggle, ExNavDropdown, ExNavDropdownHeader, ExNavDropdownItem, ExNavItem, TitleToogle } from '../../components/ExNavbar';
import { FaBuildingWheat } from "react-icons/fa6";
import { VscGitPullRequestGoToChanges } from "react-icons/vsc";

export const Principal = () => {
    const auth = useAuth();

    function alterUser(grupo: string) {
        if (auth.usuemail && (auth.ususenha || auth.usutoken)) {
            auth.authenticate(auth.usuemail, auth.ususenha, auth.usutoken, grupo).then(() => {
                window.location.reload();
            })
        }
    }

    return (
        <div style={{ height: '100vh' }}>
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container style={{ height: '60px' }}>
                    <Navbar.Brand href="/">
                        <img src={Logo} height={'40px'} alt='' />
                    </Navbar.Brand>
                    {(auth.grupos) &&
                        <Nav className="me-auto">
                            <ExNavItem>
                                <Dropdown>
                                    <DropdownToggle>
                                        <FaBuildingWheat style={{ position: 'absolute', left: 10, fontSize: '30px', top: 8 }} />
                                        <TitleToogle>Selecione o grupo:</TitleToogle>
                                        {auth.grupo.grudescricao}
                                    </DropdownToggle>

                                    <Dropdown.Menu>
                                        {
                                            auth.grupos.map((grupo: any, index: any) => (
                                                <DropdownItem key={index} onClick={() => alterUser(grupo)}>{grupo.grudescricao}</DropdownItem>
                                            ))
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </ExNavItem>
                        </Nav>
                    }
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto" />
                        <Nav className="me-auto">
                            <ExNavItem to="/usuario">Usuários</ExNavItem>
                            <ExNavItem to="/grupo">Grupo</ExNavItem>                            
                            <ExNavDropdown title="Anomalias">
                                <div style={{ width: '400px' }}>
                                    <Row>
                                        <ExNavDropdownHeader icon={<VscGitPullRequestGoToChanges />} title={'Cadastros'}>
                                            <ExNavDropdownItem to="/camposrequisicao">Campos</ExNavDropdownItem>
                                        </ExNavDropdownHeader>
                                    </Row>
                                </div>
                            </ExNavDropdown>
                        </Nav>
                        <Nav className="me-auto">
                            <ExNavItem onClick={() => auth.logout()}>Sair</ExNavItem>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar >
            <AppRoutes />
        </div >
    )
}