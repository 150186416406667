import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Col, Form, InputGroup } from "react-bootstrap";
import { ExFormLabel } from "../ExControls";
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { COLOR_PRIMARY } from "../../theme/styles";
import { toast } from "react-toastify";
import Select from 'react-select';
import "./styles.css";

const customStyles = {
    container: (baseStyles: any) => ({
        ...baseStyles,
        width: '100%'
    }),
    control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        backgroundColor: 'transparent',
        minHeight: '0px',
        width: '100%',
        border: 'none',
        boxShadow: 'none'
    }),
    dropdownIndicator: (baseStyles: any) => ({
        ...baseStyles,
        paddingTop: '2px',
        paddingBottom: '2px',
    }),
    clearIndicator: (baseStyles: any) => ({
        ...baseStyles,
        paddingTop: '2px',
        paddingBottom: '2px',
    }),
    valueContainer: (baseStyles: any) => ({
        ...baseStyles,
        paddingTop: '4px',
        paddingBottom: '4px',

    }),
    input: (baseStyles: any) => ({
        ...baseStyles,
        paddingTop: 0,
        paddingBottom: 0,
        margin: 0
    }),
    indicatorSeparator: (baseStyles: any) => ({
        ...baseStyles,
        marginTop: '4px',
        marginBottom: '4px',
        backgroundColor: '#fefefe'
    }),
    menu: (baseStyles: any) => ({
        ...baseStyles,
        backgroundColor: '#f7f7f7',
        border: '1px solid white',
        left: 0,
        zIndex: 1000
    }),
    option: (baseStyles: any, state: any) => (
        {
            ...baseStyles,
            backgroundColor: state.isSelected ? COLOR_PRIMARY : 'transparent',
            '&:hover': {
                backgroundColor: state.isSelected ? COLOR_PRIMARY : 'rgba(255, 255, 255, .1)'
            }
        }),
    singleValue: (baseStyles: any) => ({
        ...baseStyles,
        color: '#dee2e6',
        fontSize: '0.875rem'
    }),
};

interface ExSelectProps {
    ref: any;
    id: any;
    defaultValue?: any;
    options?: any;
    label?: String;
    md?: any;
    required?: any;
    model?: any;
    size?: any;
    validated?: any;
}

export const ExSelect: React.FC<ExSelectProps> = forwardRef((props: ExSelectProps, ref: any) => {
    const auth = useAuth();

    const selectRef = useRef<any>(null);

    const [options, setOptions] = useState<any[]>();
    const [value, setValue] = useState<any>();
    const [loading, setLoading] = useState<any>(props.options ? false : true);

    function getValue() {
        return value?.value
    }

    function getId() {
        return props.id
    }

    function validate() {
        const result = props.required ? (value !== '') : true;
        return result
    }

    useImperativeHandle(ref, () => ({
        getValue,
        setValue,
        getId,
        validate
    }));

    async function apiGet() {
        await auth.get(props.model.api_get).then((response: any) => {
            const dados = response.data;

            const _options = dados.map((item: any) => ({
                value: props.model.key.split('.').length > 1 ? item[props.model.key.split('.')[0]][props.model.key.split('.')[1]] : item[props.model.key],
                label: props.model.description_field.split('.').length > 1 ?
                    item[props.model.description_field.split('.')[0]][props.model.description_field.split('.')[1]] :
                    item[props.model.description_field]
            }));

            setOptions(_options);
            setLoading(false);
        }).catch((error: any) => {
            console.log(error);
            toast.error('Não foi possível carregar os valores do campo ' + props.label)
        })
    }

    useEffect(() => {
        if ((props.defaultValue !== '') && selectRef.current && (options || props.options) && (selectRef.current.getValue().length === 0)) {
            var _defaultOption: any;

            if (options)
                _defaultOption = options.find(option => option.value === props.defaultValue)
            else
                _defaultOption = props.options.find((option: any) => option.value === props.defaultValue);

            if (_defaultOption)
                setValue(_defaultOption);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, props.options]);

    useMemo(() => {
        if ((!props.options) && (props.model) && (props.model.api_get)) {
            apiGet();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.model]);

    const handleOnChange = (e: any) => {
        if (e)
            setValue(e);
    };

    return (
        <Form.Group as={Col} md={props?.md ? props.md : "12"}>
            {props.label && <ExFormLabel>{(props?.required ? '*' : '') + props.label}</ExFormLabel>}
            <InputGroup size={props.size ? props.size : "sm"} hasValidation>
                <Form.Control
                    className="custom_select"
                    ref={selectRef}
                    isInvalid={props.validated && props.required && !value}
                    isValid={props.validated && value}
                    as={Select}
                    onChange={handleOnChange}
                    placeholder=""
                    isLoading={loading}
                    options={props.options ? props.options : options}
                    required={props.required}
                    value={value}
                    styles={customStyles} />
            </InputGroup>
        </Form.Group>
    );
});
