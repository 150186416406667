import { FaUserLock } from "react-icons/fa";
import { FaBuildingWheat } from "react-icons/fa6";
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';

export const model_usuario = {
    icon: <FaUserLock />,
    name: 'Usuário',
    key: 'usucodigo',
    description_field: 'usunome',
    inactive_field: 'usuativo',
    api_get: '/v1/usuario',
    api_post: '/v1/usuarioone',
    inputs: [
        { id: 'usuemail', label: 'E-mail', type: 'text', md: 12, required: true, model: 'email' },
        { id: 'usunome', label: 'Nome', type: 'text', md: 12, required: false },
    ],
    base: {
        usunome: ''
    }
}

export const model_grupo_consulta = {
    name: 'Grupo',
    key: 'gruid',
    description_field: 'grudescricao',
    api_get: '/v1/grupoonlyuser',
}

export const model_grupo = {
    icon: <FaBuildingWheat />,
    name: 'Grupo',
    key: 'gruid',
    description_field: 'grudescricao',
    api_get: '/v1/grupoonlyuser',
    api_post: '/v1/newgrupo',
    inputs: [
        { id: 'gruid', label: 'Código', type: 'number', md: 3, disabled: true, required: true },
        { id: 'grudescricao', label: 'Descrição', type: 'text', md: 9, required: true },
        { id: 'gruidpai', label: 'Grupo Pai', type: 'select', md: 12, model: model_grupo_consulta }
    ],
    base: {
        gruativo: 1,
        grutipo: 1
    }
}

export const model_aplicativo_usuario = {
    icon: <PhonelinkSetupIcon />,
    name: 'Liberação de Aplicativos',
    key: 'aplicodigo',
    description_field: 'aplinome',
    api_get: '/v1/aplicativotouser',
}
