import styled from "styled-components";

export const Icon = styled.div`
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-right: 6px;
`
export const Title = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
`