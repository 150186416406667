import { createContext, useState, useEffect } from 'react';
import { IAuthProvider, IContext, IUser } from './types';
import { GetRequest, PostRequest, LoginRequest, getUserLocalStorage, setUserLocalStorage, getGrupoLocalStorage, setGrupoLocalStorage, SigninRequest, ValidateCodeRequest } from './util';

export const AuthContext = createContext<IContext>({} as IContext);

export const AuthProvider = ({ children }: IAuthProvider) => {
    const [user, setUser] = useState<IUser | null>();
    const [grupos, setGrupos] = useState<any | null>();

    useEffect(() => {
        const user = getUserLocalStorage();
        const grupos = getGrupoLocalStorage();

        if (user)
            setUser(user);

        if (grupos)
            setGrupos(grupos);
    }, []);

    async function setGruSuperiores(grupos: any) {
        setGrupos({ grupos });
        setGrupoLocalStorage({ grupos });
    }

    async function alterUser(data: any) {
        const payload = {
            usuemail: data.usuemail,
            ususenha: data.ususenha,
            usutoken: data.usutoken,
            token: data.token,
            refresh: data.refresh,
            usucodigo: data.usucodigo,
            usunome: data.usunome,
            versao: data._versao,
            grupo: data.grupo,
            privilegio: data.privilegio
        }

        setUser(payload);
        setUserLocalStorage(payload);
    }

    async function authenticate(usuemail: string, ususenha?: string, usutoken?: string, grupo?: any) {
        console.log('b', usuemail)
        const response = await LoginRequest(usuemail, ususenha, usutoken, grupo?.gruid)
console.log(response.data)
        if (response.status && ((response.status === 200) || (response.status === 201))) {
            if (grupo) {
                console.log(response.data)
                alterUser({
                    usuemail,
                    ususenha,
                    usutoken,
                    grupo,
                    token: response.data.token,
                    refresh: response.data.refresh,
                    usucodigo: response.data.usucodigo,
                    usunome: response.data.usunome,
                    versao: response.data._versao,
                    //privilegio: response.data.privcrmid ?? 1
                })
            } else
                return response.data.token;
        } else
            throw response;
    }

    async function signin(usuemail: string, usunome: string) {
        const response = await SigninRequest(usuemail, usunome)

        if (response.status && ((response.status === 200) || (response.status === 201))) {
            return true;
        } else
            throw response;
    }

    async function validateCode(usuemail: string, usuvalcodigo: string) {
        const response = await ValidateCodeRequest(usuemail, usuvalcodigo)
console.log(response.data)
        if (response.status && ((response.status === 200) || (response.status === 201))) {
            return response.data.usutoken;
        } else
            throw response;
    }

    function logout() {
        setUser(null);
        setUserLocalStorage(null);
    }

    async function get(prefix: string, params?: any) {
        const response = await GetRequest(prefix, user?.token, params);

        if ((response.response) && (response.response.status === 406)) {
            logout();
            return response
        }

        if (response.status && ((response.status === 200) || (response.status === 201))) {
            return response
        } else
            throw response;
    }

    async function post(url: string, data: any) {
        const response = await PostRequest(url, user?.token, data);

        if (response.status && ((response.status === 200) || (response.status === 201))) {
            return response
        } else
            throw response;
    }

    return (
        <AuthContext.Provider value={{ ...user, ...grupos, authenticate, signin, validateCode, logout, alterUser, get, post, setGruSuperiores }}>
            {children}
        </AuthContext.Provider>
    )
}