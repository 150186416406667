import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { COLOR_BACKGROUND_DARK } from '../../theme/styles';

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 36,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#e8e8e8',
            '& + .MuiSwitch-track': {
                backgroundColor: COLOR_BACKGROUND_DARK,
                borderColor: COLOR_BACKGROUND_DARK,
                opacity: 1,
                border: 0,
            },
            '& .MuiSwitch-thumb': {
                backgroundColor: 'lightgray'
            }
        }
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 16,
        height: 16,
        backgroundColor: 'gray',
    },
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        backgroundColor: 'white',
        border: 'lightgray 1px solid',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

export const ExSwitch: React.FC<SwitchProps> = (props) => {
    return (
        <IOSSwitch
            {...props}
        />
    )
}