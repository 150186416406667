import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './contexts/AuthProvider';
import "./index.css";
import { GoogleOAuthProvider } from '@react-oauth/google';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    // <React.StrictMode>
    <GoogleOAuthProvider clientId={'533879668791-jqihqhicniim0eg69ngs8k8g1rtcrnt1.apps.googleusercontent.com'}>
        <AuthProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </AuthProvider >
    </GoogleOAuthProvider>
    //</React.StrictMode>
);