import { Route, Routes } from "react-router-dom"
import { RequireAuth } from "../contexts/AuthProvider/RequireAuth"
import { Usuario } from "../pages/Usuario"
import { Grupo } from "../pages/Grupo"

export const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/usuario" element={<RequireAuth><Usuario /></RequireAuth>} />
            <Route path="/grupo" element={<RequireAuth><Grupo /></RequireAuth>} />
        </Routes>
    )
}