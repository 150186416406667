import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useAuth } from '../../contexts/AuthProvider/useAuth';
import { DataTable } from '../DataTable';
import { Column } from 'primereact/column';
import { Card } from 'react-bootstrap';
import { isMobileDevice } from '../../commons/utils';
import { CardTable, Header, Title, Icon } from './components';
import { ExSwitch } from '../ExSwitch';
import { ExButton } from '../ExButton';
import { FaPlus } from "react-icons/fa6";
import { ExCadastroPadrao } from '../ExCadastroPadrao';
import { toast } from 'react-toastify';
import { confirm } from '../Confirmation';
import { ExTooltip } from '../Tooltip';
import { COLOR_PRIMARY } from '../../theme/styles';
import CreateIcon from '@mui/icons-material/Create';

interface ConsultaPadraoProps {
    ref?: any;
    model: any;
    columns: any;
    sortField?: any;
    details?: any;
    functions?: any;
    apifilter?: any;
    disableedit?: any;
}

export const ConsultaPadrao: React.FC<ConsultaPadraoProps> = forwardRef((props, ref) => {
    const auth = useAuth();

    const refCadastroPadrao = useRef<any>();

    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState<any>(true);
    const [expandedRows, setExpandedRows] = useState([]);

    useImperativeHandle(ref, () => ({
        refresh
    }));

    useEffect(() => {
        refresh();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function refresh() {
        setLoading(true);

        auth.get(props.model.api_get + (props.apifilter ? props.apifilter : '')).then((response: any) => {
            if (process.env.NODE_ENV === 'development')
                console.log('Consulta ' + props.model.name, response.data);

            setData(response.data)
        }).catch((error: any) => {
            console.log(error);
        }).finally(() => {
            setLoading(false)
        });
    }

    async function ativaInativa(row: any, ativo: boolean) {
        if ((ativo) || (await confirm('Deseja mesmo inativar este registro?'))) {
            let body: any = { ...row };

            delete body.ususenha;

            body[props.model.inactive_field] = ativo ? 1 : 0;

            console.log(body)

            auth.post(props.model.api_post, body).then(() => {
                toast.success('Registro ' + (ativo ? 'ativado' : 'inativado') + ' com sucesso.');
                refresh();
            }).catch((error: any) => {
                toast.error(error.response.data.error ? error.response.data.error : 'Erro ao concluir a operação.');
                console.log(error)
            })
        }
    }

    function tableAtivoInativo(row: any) {
        return (
            <ExSwitch checked={row[props.model.inactive_field] === 1} onChange={(e: any) => ativaInativa(row, e.target.checked)} />
        )
    }

    function tableFunctions(row: any) {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {props.functions &&
                    props.functions(row)?.map((item: any, i: number) => (
                        <ExTooltip key={i} title={item.label}>
                            <ExButton size='sm' color={item.color} className='m-0 me-2 p-1' style={{ borderRadius: '15%', minHeight: '3em', minWidth: '3em', justifyContent: 'center' }} onClick={() => item.click(row)} iconcenter={item.icon} />
                        </ExTooltip>
                    ))}

                {!props.disableedit &&
                    <ExButton size='sm' color={COLOR_PRIMARY} className='m-0 me-2 p-1' style={{ borderRadius: '15%', minHeight: '3em', minWidth: '3em', justifyContent: 'center' }} onClick={() => refCadastroPadrao.current.openModal(row)} iconcenter={<CreateIcon />} />
                }
            </div>
        )
    }

    return (
        <>
            <CardTable className={isMobileDevice() ? "m-3 mb-4 mt-3" : "m-4 mb-4"}>
                <Card.Body className={isMobileDevice() ? "p-1" : "p-2"}>
                    <Header>
                        <Title>
                            <Icon>{props.model?.icon}</Icon>
                            {props.model?.name}
                        </Title>
                        {props.model.base &&
                            <ExButton icon={<FaPlus />} onClick={() => refCadastroPadrao.current.openModal()}>Novo</ExButton>
                        }
                    </Header>
                    <DataTable
                        className="m-0"
                        value={data}
                        loading={loading}
                        refresh={refresh}
                        sortField={props.sortField}
                        rowExpansionTemplate={props.details}
                        expandedRows={expandedRows}
                        onRowToggle={(e: any) => setExpandedRows(e.data)}
                    >
                        {props.details && <Column expander={true} style={{ width: '2.5rem', marginBottom: '-10px' }} />}
                        {
                            props.columns.map((col: any, i: number) => (
                                <Column
                                    key={col.field}
                                    sortable={(col.notSortable || col.field === 'functions' || col.header === 'Ativo') ? false : true}
                                    field={col.field}
                                    align={isMobileDevice() ? 'right' : 'left'}
                                    header={col.header}
                                    body={col.field === 'functions' ? (e) => tableFunctions(e) : col.header === 'Ativo' ? (e) => tableAtivoInativo(e) : col.body}
                                    style={{ ...col.style, marginBottom: `${(i + 1) === props.columns.length ? '0px' : '-14px'}` }}
                                />
                            ))
                        }
                    </DataTable>
                </Card.Body>
            </CardTable>
            {props.model.base &&
                <ExCadastroPadrao ref={refCadastroPadrao} model={props.model} success={() => refresh()} />
            }
        </>
    )
})