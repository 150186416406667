import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#676565f'
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#676565f'
    },
}));

export const ExTooltip = (props: any) => {
    return (
        <BootstrapTooltip title={props.title} style={{ ...props.style }}>
            <div>
                {props.children}
            </div>
        </BootstrapTooltip>
    );
}